import { Component, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { OrganizationsService } from '@features/organizations/services/organizations.service';
import { catchError, map, of, startWith, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-menu-organizations',
  templateUrl: './menu-organizations.component.html',
  styleUrl: './menu-organizations.component.scss',
})
export class MenuOrganizationsComponent {
  isLoading = signal<boolean>(false);
  formFilter = new FormControl('');
  organizationList = toSignal(
    this.organizationsService.getList().pipe(
      map((data) => {
        this.isLoading.set(true);
        return {
          list: data,
          filtered: data,
        };
      }),
      tap(() => {
        this.isLoading.set(false);
      }),
      switchMap((data) => {
        return this.formFilter.valueChanges.pipe(
          startWith(''),
          map((filter: string | null) => {
            if (filter) {
              data.filtered = data.list.filter((el) =>
                el.name.toLocaleLowerCase().includes((filter || '').trim().toLowerCase()),
              );
            } else {
              data.filtered = [...data.list];
            }
            return data;
          }),
        );
      }),
      catchError(() => {
        return of(null);
      }),
    ),
  );

  constructor(private readonly organizationsService: OrganizationsService) {}
}
