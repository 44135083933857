<mat-card class="col" appearance="outlined">
  <mat-card-header>
    <h3 class="title">
      {{ statistic()?.name }}
    </h3>
  </mat-card-header>
  <mat-card-content class="d-grid justify-content-center statistic">
    <span>{{ statistic()?.amount }}</span>
    <span>{{ statistic()?.fullyReady }} fully ready</span>
  </mat-card-content>
</mat-card>
