import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { MenuOrganizationsComponent } from './components/menu-organizations/menu-organizations.component';
import { PageOrganizationsComponent } from './components/page-organizations/page-organizations.component';
import { ProductTileOrganizationsComponent } from './components/product-tile-organizations/product-tile-organizations.component';
import { StatisticsTileOrganizationsComponent } from './components/statistics-tile-organizations/statistics-tile-organizations.component';
import { StatusTableOrganizationsComponent } from './components/status-table-organizations/status-table-organizations.component';
import { OrganizationsRoutingModule } from './organizations-routing.module';
import { OrganizationsService } from './services/organizations.service';

@NgModule({
  declarations: [
    PageOrganizationsComponent,
    StatisticsTileOrganizationsComponent,
    ProductTileOrganizationsComponent,
    StatusTableOrganizationsComponent,
    MenuOrganizationsComponent,
  ],
  providers: [OrganizationsService],
  exports: [MenuOrganizationsComponent],
  imports: [CommonModule, SharedModule, OrganizationsRoutingModule, ReactiveFormsModule],
})
export class OrganizationsModule {}
