import { Component, input } from '@angular/core';
import { ProductItemDTO } from '@features/organizations/models';

@Component({
  selector: 'app-product-tile-organizations',
  templateUrl: './product-tile-organizations.component.html',
  styleUrl: './product-tile-organizations.component.scss',
})
export class ProductTileOrganizationsComponent {
  product = input.required<ProductItemDTO, ProductItemDTO>({
    transform: (item: ProductItemDTO) => {
      item['imgUrl'] = this.productsMap.get(item.link_type);
      return item;
    },
  });

  productsMap = new Map([
    ['DataHub', 'assets/products/dataHub.png'],
    ['AirFlow', 'assets/products/airflow.png'],
    ['AirByte', 'assets/products/airbype.png'],
    ['QVERA', 'assets/products/qvera.png'],
    ['SFTP', 'assets/products/SFTP.png'],
    ['Snowflake', 'assets/products/snowflake.png'],
    ['ThoughtSpot', 'assets/products/thoughtSpot.png'],
  ]);
}
