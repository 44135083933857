<mat-card appearance="outlined">
  <mat-card-header>
    <h3 class="title">Status of {{ data().title }}</h3>
  </mat-card-header>
  <mat-card-content>
    <table mat-table matSort [dataSource]="data().dataSource" class="mat-elevation-z8">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <mat-chip-set aria-label="Status" class="status-chip" [ngClass]="element.status | statusColor">
            <mat-chip>{{ element.status }}</mat-chip>
          </mat-chip-set>
        </td>
      </ng-container>

      <!-- Last Used Column -->
      <ng-container matColumnDef="lastUsed">
        <th mat-header-cell *matHeaderCellDef>Last Used</th>
        <td mat-cell *matCellDef="let element">{{ element?.last_run_start }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="6">
          @if (data().length === 0) {
            <div class="empty-table">
              <div class="empty-table-message-info">No information here yet</div>
            </div>
          } @else {
            <div class="mat-mdc-row mdc-data-table__cell">No data</div>
          }
        </td>
      </tr>
    </table>
    <button mat-button color="primary" type="button" class="action-button" (click)="router.navigateByUrl(data().url)">
      see more
    </button>
  </mat-card-content>
</mat-card>
