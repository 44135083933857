import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageOrganizationsComponent } from './components/page-organizations/page-organizations.component';
import { MenuOrganizationsComponent } from './components/menu-organizations/menu-organizations.component';

const routes: Routes = [
  {
    path: ':id',
    component: PageOrganizationsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrganizationsRoutingModule {}
