<div class="container container-xxxl pt-3 d-grid">
  @if (isLoading()) {
    <mat-progress-bar mode="query"></mat-progress-bar>
  }
  @if (organizationData(); as organizationData) {
    <div class="row align-items-center">
      <div class="col d-grid titleSection">
        <span class="align-items-center organizationName">{{ organizationData.organization.name }}</span>
        <span class="bage">{{ organizationData.organization.subscription.customer_type }}</span>
        <span class="customerType">
          Type of customer:
          <b>{{ organizationData.organization.subscription.package_level }}</b>
        </span>
      </div>
      <div class="col d-grid actionsSection">
        <button mat-stroked-button color="primary" disabled="true" matTooltip="to be implemented">
          Add as favorites
        </button>
        <button mat-flat-button color="primary" [routerLink]="['']">View details</button>
      </div>
    </div>
    <div class="row pt-3">
      @for (statistic of statistics(); track statistics.name) {
        <app-statistics-tile-organizations class="col" [statistic]="statistic"></app-statistics-tile-organizations>
      }
    </div>
    <div class="row pt-3 g-2 d-grid productsSection">
      @for (product of products(); track product) {
        <app-product-tile-organizations class="col" [product]="product"></app-product-tile-organizations>
      }
    </div>
    <div class="row pt-3">
      <app-status-table-organizations class="col" [data]="dataSources()"></app-status-table-organizations>
      <app-status-table-organizations class="col" [data]="pipelines()"></app-status-table-organizations>
    </div>
  }
</div>
