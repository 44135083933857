<div class="p-3">
  <mat-form-field class="input-normalize">
    <input type="search" matInput [formControl]="formFilter" placeholder="Search organization" />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  <mat-nav-list>
    @if (isLoading()) {
      <mat-progress-bar mode="query"></mat-progress-bar>
    }
    @for (organization of organizationList()?.filtered; track organization) {
      <a mat-list-item href="..." routerLink="/organizations/{{ organization.pk }}">{{ organization.name }}</a>
    } @empty {
      <mat-list-item role="listitem">No organizations found</mat-list-item>
    }
  </mat-nav-list>
</div>
