import { Component, input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-status-table-organizations',
  templateUrl: './status-table-organizations.component.html',
  styleUrl: './status-table-organizations.component.scss',
})
export class StatusTableOrganizationsComponent {
  data = input.required<any>();
  displayedColumns = ['name', 'status', 'lastUsed'];
  //todo: add status colors
  //todo: format time

  constructor(public router: Router) {}
}
