import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params } from '@angular/router';
import { DataSourceItemDTO } from '@features/data-sources/models';
import { InfoMessagesService } from '@features/info-messages';
import { OrganizationDetailsDTO, PipelineItemDTO, ProductItemDTO } from '@features/organizations/models';
import { OrganizationsService } from '@features/organizations/services/organizations.service';
import { catchError, finalize, Observable, of, switchMap, tap } from 'rxjs';

export interface StatusTable {
  amount: number;
  dataSource: DataSourceItemDTO[] | PipelineItemDTO[] | [];
  title: string;
  url: string;
}

@Component({
  selector: 'app-page-organizations',
  templateUrl: './page-organizations.component.html',
  styleUrl: './page-organizations.component.scss',
})
export class PageOrganizationsComponent {
  isLoading = signal<boolean>(false);
  products = signal<ProductItemDTO[]>([]);
  statistics = signal<any>([]);

  // todo: specify routes ones it's ready
  dataSources = signal<StatusTable>({
    amount: 0,
    dataSource: [],
    title: 'Data Sources',
    url: '',
  });
  pipelines = signal<StatusTable>({
    amount: 0,
    dataSource: [],
    title: 'Pipelines',
    url: '',
  });

  organizationData = toSignal(
    this.activatedRoute.params.pipe(
      tap(() => {
        this.isLoading.set(true);
      }),
      switchMap((params: Params): Observable<OrganizationDetailsDTO | null> => {
        const id = params['id'];

        return this.organizationsService.getData(id).pipe(
          tap((data) => {
            this.products.set(data.organization.product_links);
            // todo: show only 4 elements based on statuses(?) ones requirenments provided
            this.statistics.set([
              {
                name: 'Data Sources',
                amount: data.data_sources.length,
                fullyReady: 0,
              },
              {
                name: 'Pipelines',
                amount: data.pipelines.length,
                fullyReady: 0,
              },
            ]);
            this.dataSources.update((obj) => ({
              ...obj,
              amount: data.data_sources.length,
              dataSource: data.data_sources.slice(0, 4),
            }));
            this.pipelines.update((obj) => ({
              ...obj,
              amount: data.pipelines.length,
              dataSource: data.pipelines.slice(0, 4),
            }));
          }),
          finalize(() => {
            this.isLoading.set(false);
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === HttpStatusCode.NotFound)
              this.infoMessagesService.showError(`Organization with ID '${id}' was not found!`);
            return of(null);
          }),
        );
      }),
    ),
  );

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly organizationsService: OrganizationsService,
    private readonly infoMessagesService: InfoMessagesService,
  ) {}
}
